import React, { useEffect, useState } from 'react';
import { auth, database } from '../firebase'; 
import { ref, get, child } from 'firebase/database';

const Invite = () => {
  const [referralLink, setReferralLink] = useState('');
  const [copySuccess, setCopySuccess] = useState('');
  const [domainName, setDomainName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const currentUser = auth.currentUser;

      if (currentUser) {
        const userId = currentUser.uid;
        
        try {
          // Fetch user data from Firebase Realtime Database
          const dbRef = ref(database);
          const userSnapshot = await get(child(dbRef, `users/${userId}`));
          
          if (userSnapshot.exists()) {
            const username = userSnapshot.val().username;
            
            // Fetch domain name from Firebase Realtime Database
            const websiteSnapshot = await get(child(dbRef, `websites`));
            
            if (websiteSnapshot.exists()) {
              const domain = websiteSnapshot.val().domain;
              setDomainName(domain);
              
              // Generate the referral link with the domain name
              const link = `https://${domain}/register?ref=${username}`;
              setReferralLink(link);
            } else {
              console.log('No website data found');
            }
          } else {
            console.log('No user data found');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, []);

  // Function to copy referral link to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        setCopySuccess('Referral link copied!');
      })
      .catch(err => {
        console.error('Failed to copy:', err);
      });
  };

  return (
    <div className="bg-gray-900 text-white p-8 rounded-lg shadow-lg w-full max-w-md mx-auto">
  <b className="text-2xl font-semibold mb-4 block text-center">Invite Friends</b>
  
  {referralLink && (
    <div className="mb-6">
      <p className="text-lg mb-2">Your referral link:</p>
      <input 
        type="text" 
        value={referralLink} 
        readOnly 
        className="w-full p-2 bg-gray-800 text-white rounded-md border border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  )}
  
  <button 
    onClick={copyToClipboard} 
    className="w-full py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
  >
    Copy Invite Link
  </button>

  {copySuccess && (
    <p className="text-green-500 text-lg mt-4 text-center">{copySuccess}</p>
  )}
</div>

  );
};

export default Invite;
