import React, { useState, useEffect } from "react";
import { database, auth } from "../firebase";
import { ref, get } from "firebase/database";
import { useNavigate } from "react-router-dom";

const Wallet = ({ onSelectComponent }) => {
  const navigate = useNavigate();
  const [currentBalance, setCurrentBalance] = useState(0);
  const [eBalance, setEBalance] = useState(0);
  const [pendingWithdraw, setPendingWithdraw] = useState(0);
  const [approvedWithdraw, setApprovedWithdraw] = useState(0);
  const [withdrawals, setWithdrawals] = useState([]);
  const [userId, setUserId] = useState(null);
  const [showWithdraw, setShowWithdraw] = useState(false); // State to manage withdraw visibility

  const handleWithdrawClick = () => {
    setShowWithdraw(true); // Show Withdraw component
    onSelectComponent("withdraw"); // Update the active component in parent
  };

  const showAlert = () => {
    alert("Withdraw on Rank 10 only!!");
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
        try {
          const userRef = ref(database, `users/${user.uid}`);
          const snapshot = await get(userRef);

          if (snapshot.exists()) {
            const userData = snapshot.val();
            setCurrentBalance(userData.coins || 0);
            setEBalance(userData.ecoins || 0);
            setPendingWithdraw(userData.pendingWithdraw || 0);
            setApprovedWithdraw(userData.approvedWithdraw || 0);
          } else {
            console.log("No user data found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const fetchWithdrawals = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const withdrawRef = ref(database, `withdraws/${user.uid}/`);
          const snapshot = await get(withdrawRef);

          if (snapshot.exists()) {
            const withdrawalsData = snapshot.val();

            const withdrawalsList = Object.keys(withdrawalsData).map((key) => ({
              id: key, // Use the unique Firebase key as ID
              accountHolder: withdrawalsData[key].accountHolder,
              accountNumber: withdrawalsData[key].accountNumber,
              amount: withdrawalsData[key].amount,
              date: withdrawalsData[key].date,
              paymentMethod: withdrawalsData[key].paymentMethod,
              paymentStatus: withdrawalsData[key].paymentStatus,
            }));

            // Set the list of withdrawals in the state
            setWithdrawals(withdrawalsList);
            console.log(withdrawalsList);
          } else {
            console.log("No withdrawal data found");
          }
        } catch (error) {
          console.error("Error fetching withdrawal data:", error);
        }
      }
    };

    fetchUserData();
    fetchWithdrawals();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A"; // Handle missing date
    const dateObj = new Date(dateString);
    return !isNaN(dateObj.getTime())
      ? dateObj.toLocaleString()
      : "Invalid Date";
  };

  return (
    <div className="bg-gray-900 text-white p-2 md:p-6 rounded-xl  shadow-lg mt-4">
      {/* Wallet Overview */}
      <div className=" flex justify-between items-center mb-6">
        <span className="text-sm  md:text-lg font-semibold">
          Current Balance:
        </span>
        <span className="text-sm md:text-4xl font-extrabold text-yellow-400">
          {currentBalance}Rs
        </span>
      </div>

      <div className=" flex justify-between gap-4 mb-6">
        <button
          className=" bg-blue-600 hover:bg-blue-700 text-white   md:font-semibold text-sm py-1 px-3 md:py-3 md:px-6 rounded-lg shadow-md w-full sm:w-auto transition-all duration-300"
          onClick={handleWithdrawClick}
        >
          Withdraw current balance
        </button>
        <button
          className=" bg-green-600 hover:bg-green-700 text-white   md:font-semibold text-sm py-1 px-3 md:py-3 md:px-6 rounded-lg shadow-md w-full sm:w-auto transition-all duration-300"
          onClick={showAlert}
        >
          Withdraw in Best wallet: {eBalance}Rs
        </button>
      </div>

      <div className=" flex justify-between items-center mb-6">
        <span className=" text-sm md:text-lg font-semibold">
          Pending Withdraw:
        </span>
        <span className=" text-sm md:text-3xl font-extrabold text-yellow-400">
          {pendingWithdraw}Rs
        </span>
      </div>

      <div className=" flex justify-between items-center mb-6">
        <span className="text-sm md:text-lg font-semibold">
          Approved Withdraw:
        </span>
        <span className="text-sm md:text-3xl font-extrabold text-yellow-400">
          {approvedWithdraw}Rs
        </span>
      </div>

      {/* History Section */}
      <h2 className="text-white text-sm md:text-3xl font-semibold mb-6">
        Withdrawal History
      </h2>
      <div className="overflow-x-auto bg-gray-800 rounded-lg shadow-lg p-4">
        <table className="min-w-full table-auto text-left">
          {/* Table Header */}
          <thead>
            <tr className="bg-gray-700">
              <th className="text-white text-[10px] md:text-sm font-semibold py-1 px-3 md:py-3 md:px-6">
                 Amount
              </th>
              <th className="text-white text-[10px] md:text-sm font-semibold py-1 px-3 md:py-3 md:px-6">
                 Status
              </th>
              <th className="text-white text-[10px] md:text-sm font-semibold py-1 px-3 md:py-3 md:px-6">
                Date & Time
              </th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {withdrawals.map((withdrawal, index) => (
              <tr key={index} className="hover:bg-gray-700">
                <td className="text-white text-sm md:text-lg py-1 px-3 md:py-3 md:px-6">{withdrawal.amount}Rs</td>
                <td className="text-white text-sm md:text-lg py-1 px-3 md:py-3 md:px-6">
                  {withdrawal.paymentStatus}
                </td>
                <td className="text-white text-xs md:text-lg py-1 px-3 md:py-3 md:px-6">
                  {formatDate(withdrawal.date)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Wallet;