import React, { useEffect, useState } from "react";
import Logo from "../Admin/Logo";
import { FaPhoneAlt, FaRegUserCircle } from "react-icons/fa";
import { child, get, ref } from "firebase/database";
import { auth, database } from '../firebase'; // Import Firebase services
import useUserData from "../hooks/useUserData";


const Nav = ({isOpen, toggleSidebar ,onSelectComponent , activeComponent }) => {
  const { data: userData, loading, error } = useUserData(); // Use the hook


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!userData) return <p>No user data available</p>;

  
  return (
    <nav
      id="sidebar"
      className={`lg:w-[270px] max-lg:fixed transition-all duration-500 shrink-0 z-[100] ${
        isOpen ? "max-lg:w-[270px] max-lg:visible" : "max-lg:w-0 max-lg:invisible"
      }`}
    >
      <div
        id="sidebar-collapse-menu"
        className={`bg-[#081028] shadow-lg h-screen fixed top-0 left-0 overflow-auto overflow-x-hidden z-[99] transition-all duration-500 ${
          isOpen ? "max-lg:w-[270px] max-lg:visible" : "max-lg:w-0 max-lg:invisible"
        }`}
      >
        <div class="bg-[#081028] flex items-center gap-4 pt-6 pb-2 px-4 sticky top-0 min-h-[64px] z-[100]">
          <a  class="flex items-center gap-2" >
            <Logo className={"w-8 h-8"} />
            <p class="text-base font-semibold text-gray-300 tracking-wide text-center">
              Dashboard
            </p>
          </a>
          {/* Add the SVG button to close the sidebar */}
          <button 
            onClick={toggleSidebar} 
            className="ml-auto p-2 text-gray-300 hover:bg-[#0b1739] rounded lg:hidden "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          
        </div>

        <div class="py-4 px-4">
          <div class="flex relative bg-[#0b1739] px-3 py-2.5 rounded-md border border-gray-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-4 mr-1 inline fill-gray-400"
              viewBox="0 0 118.783 118.783"
            >
              <path
                d="M115.97 101.597 88.661 74.286a47.75 47.75 0 0 0 7.333-25.488c0-26.509-21.49-47.996-47.998-47.996S0 22.289 0 48.798c0 26.51 21.487 47.995 47.996 47.995a47.776 47.776 0 0 0 27.414-8.605l26.984 26.986a9.574 9.574 0 0 0 6.788 2.806 9.58 9.58 0 0 0 6.791-2.806 9.602 9.602 0 0 0-.003-13.577zM47.996 81.243c-17.917 0-32.443-14.525-32.443-32.443s14.526-32.444 32.443-32.444c17.918 0 32.443 14.526 32.443 32.444S65.914 81.243 47.996 81.243z"
                data-original="#000000"
              />
            </svg>
            <input
              class="text-sm text-gray-300 outline-none bg-transparent px-1 max-w-[200px]"
              placeholder="Search..."
            />
          </div>
          <ul class="space-y-2 mt-6">
            {/* Dashbboard */}
            <li>
              <a
                onClick={() => onSelectComponent("dashboard")}
                className={`text-sm flex items-center cursor-pointer px-3 py-2.5 transition-all duration-300 rounded-md 
                    ${activeComponent === "dashboard" ? "bg-[#1a3d56] text-white" : "text-gray-300 hover:bg-[#0b1739]"}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="w-[18px] h-[18px] mr-3"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M19.56 23.253H4.44a4.051 4.051 0 0 1-4.05-4.05v-9.115c0-1.317.648-2.56 1.728-3.315l7.56-5.292a4.062 4.062 0 0 1 4.644 0l7.56 5.292a4.056 4.056 0 0 1 1.728 3.315v9.115a4.051 4.051 0 0 1-4.05 4.05zM12 2.366a2.45 2.45 0 0 0-1.393.443l-7.56 5.292a2.433 2.433 0 0 0-1.037 1.987v9.115c0 1.34 1.09 2.43 2.43 2.43h15.12c1.34 0 2.43-1.09 2.43-2.43v-9.115c0-.788-.389-1.533-1.037-1.987l-7.56-5.292A2.438 2.438 0 0 0 12 2.377z"
                    data-original="#000000"
                  />
                  <path
                    d="M16.32 23.253H7.68a.816.816 0 0 1-.81-.81v-5.4c0-2.83 2.3-5.13 5.13-5.13s5.13 2.3 5.13 5.13v5.4c0 .443-.367.81-.81.81zm-7.83-1.62h7.02v-4.59c0-1.933-1.577-3.51-3.51-3.51s-3.51 1.577-3.51 3.51z"
                    data-original="#000000"
                  />
                </svg>
                <span class="overflow-hidden text-ellipsis whitespace-nowrap">
                  Dashboard
                </span>
              </a>
            </li>
            {/* Today Work */}
            <li>
              <a
                onClick={() => onSelectComponent("todaywork")}
                className={`text-sm flex items-center cursor-pointer px-3 py-2.5 transition-all duration-300 rounded-md 
                    ${activeComponent === "todaywork" ? "bg-[#1a3d56] text-white" : "text-gray-300 hover:bg-[#0b1739]"}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="w-[18px] h-[18px] mr-3"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M18 2c2.206 0 4 1.794 4 4v12c0 2.206-1.794 4-4 4H6c-2.206 0-4-1.794-4-4V6c0-2.206 1.794-4 4-4zm0-2H6a6 6 0 0 0-6 6v12a6 6 0 0 0 6 6h12a6 6 0 0 0 6-6V6a6 6 0 0 0-6-6z"
                    data-original="#000000"
                  />
                  <path
                    d="M12 18a1 1 0 0 1-1-1V7a1 1 0 0 1 2 0v10a1 1 0 0 1-1 1z"
                    data-original="#000000"
                  />
                  <path
                    d="M6 12a1 1 0 0 1 1-1h10a1 1 0 0 1 0 2H7a1 1 0 0 1-1-1z"
                    data-original="#000000"
                  />
                </svg>
                <span class="overflow-hidden text-ellipsis whitespace-nowrap">
                  Today Work
                </span>
              </a>
            </li>
            {/* Your Wallet */}
            <li>
              <a
                onClick={() => onSelectComponent("wallet")}

                className={`text-sm flex items-center cursor-pointer px-3 py-2.5 transition-all duration-300 rounded-md 
                    ${activeComponent === "wallet" ? "bg-[#1a3d56] text-white" : "text-gray-300 hover:bg-[#0b1739]"}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="w-[20px] h-[20px] mr-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M2 7c0-2.21 1.79-4 4-4h12c2.21 0 4 1.79 4 4v2h-2V7c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-2h2v2c0 2.21-1.79 4-4 4H6c-2.21 0-4-1.79-4-4V7z" />
                  <path d="M16 13a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                </svg>

                <span class="overflow-hidden text-ellipsis whitespace-nowrap">
                  You Wallet
                </span>
              </a>
            </li>
            {/* My Team */}
            <li>
              <a
                onClick={() => onSelectComponent("team")}
                className={`text-sm flex items-center cursor-pointer px-3 py-2.5 transition-all duration-300 rounded-md 
                    ${activeComponent === "team" ? "bg-[#1a3d56] text-white" : "text-gray-300 hover:bg-[#0b1739]"}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="w-[18px] h-[18px] mr-3"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M437.02 74.98C388.668 26.63 324.379 0 256 0S123.332 26.629 74.98 74.98C26.63 123.332 0 187.621 0 256s26.629 132.668 74.98 181.02C123.332 485.37 187.621 512 256 512s132.668-26.629 181.02-74.98C485.37 388.668 512 324.379 512 256s-26.629-132.668-74.98-181.02zM111.105 429.297c8.454-72.735 70.989-128.89 144.895-128.89 38.96 0 75.598 15.179 103.156 42.734 23.281 23.285 37.965 53.687 41.742 86.152C361.641 462.172 311.094 482 256 482s-105.637-19.824-144.895-52.703zM256 269.507c-42.871 0-77.754-34.882-77.754-77.753C178.246 148.879 213.13 114 256 114s77.754 34.879 77.754 77.754c0 42.871-34.883 77.754-77.754 77.754zm170.719 134.427a175.9 175.9 0 0 0-46.352-82.004c-18.437-18.438-40.25-32.27-64.039-40.938 28.598-19.394 47.426-52.16 47.426-89.238C363.754 132.34 315.414 84 256 84s-107.754 48.34-107.754 107.754c0 37.098 18.844 69.875 47.465 89.266-21.887 7.976-42.14 20.308-59.566 36.542-25.235 23.5-42.758 53.465-50.883 86.348C50.852 364.242 30 312.512 30 256 30 131.383 131.383 30 256 30s226 101.383 226 226c0 56.523-20.86 108.266-55.281 147.934zm0 0"
                    data-original="#000000"
                  />
                </svg>
                <span class="overflow-hidden text-ellipsis whitespace-nowrap">
                  My Team
                </span>
              </a>
            </li>
            {/* Invite Team */}
            <li>
              <a
                onClick={() => onSelectComponent("invite")}
                
                className={`text-sm flex items-center cursor-pointer px-3 py-2.5 transition-all duration-300 rounded-md 
                    ${activeComponent === "invite" ? "bg-[#1a3d56] text-white" : "text-gray-300 hover:bg-[#0b1739]"}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="w-[18px] h-[18px] mr-3"
                  viewBox="0 0 25 25"
                >
                  <g data-name="Action Expand">
                    <path
                      d="M21.5 1.25h-18A2.25 2.25 0 0 0 1.25 3.5v18a2.25 2.25 0 0 0 2.25 2.25h18a2.25 2.25 0 0 0 2.25-2.25v-18a2.25 2.25 0 0 0-2.25-2.25zm.75 20.25a.75.75 0 0 1-.75.75h-18a.75.75 0 0 1-.75-.75v-18a.75.75 0 0 1 .75-.75h18a.75.75 0 0 1 .75.75z"
                      data-original="#000000"
                    />
                    <path
                      d="M11.75 7.25h1.5v10.5h-1.5z"
                      data-original="#000000"
                    />
                    <path
                      d="M7.25 11.75h10.5v1.5H7.25z"
                      data-original="#000000"
                    />
                  </g>
                </svg>
                <span class="overflow-hidden text-ellipsis whitespace-nowrap">
                  Invite Team
                </span>
              </a>
            </li>
          </ul>

          <hr class="border-gray-600 my-6" />

          <div>
            <ul class="space-y-2">
              <li>
                <a
                onClick={() => onSelectComponent("reward")}
                  
                className={`text-sm flex items-center cursor-pointer px-3 py-2.5 transition-all duration-300 rounded-md 
                    ${activeComponent === "reward" ? "bg-[#1a3d56] text-white" : "text-gray-300 hover:bg-[#0b1739]"}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="w-[22px] h-[22px] mr-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2a1 1 0 0 1 .923.617l2.045 4.145 4.573.665a1 1 0 0 1 .554 1.706l-3.308 3.224.78 4.551a1 1 0 0 1-1.45 1.054L12 16.907l-4.116 2.162a1 1 0 0 1-1.45-1.054l.78-4.551-3.308-3.224a1 1 0 0 1 .554-1.706l4.573-.665 2.045-4.145A1 1 0 0 1 12 2zm0 13.47 3.692 1.94-.705-4.11 2.985-2.912-4.128-.6L12 5.51 9.156 9.788l-4.128.6 2.985 2.912-.705 4.11L12 15.47z" />
                    <path d="M4 20h16a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2z" />
                  </svg>

                  <span>Daily Reward </span>
                </a>
              </li>
              <li>
                <a
                    onClick={() => onSelectComponent("contact")}
                    className={`text-sm flex items-center cursor-pointer px-3 py-2.5 transition-all duration-300 rounded-md 
                        ${activeComponent === "contact" ? "bg-[#1a3d56] text-white" : "text-gray-300 hover:bg-[#0b1739]"}`}
                >
                  <FaPhoneAlt className="w-4 h-4 mr-5" />
                  <span>Contact us</span>
                </a>
              </li>
            </ul>

            <a 
                onClick={() => onSelectComponent("profile")}
            >
                <div class="mt-6 flex items-center cursor-pointer">
                 <FaRegUserCircle className="w-9 h-9 " color="white"/>
                  <div class="ml-4">
                    <p class="text-sm text-gray-300 whitespace-nowrap">{userData?.username || 'N/A'}</p>
                    <p class="text-xs text-gray-400 whitespace-nowrap">
                      Active in B2B
                    </p>
                  </div>
                </div>
            </a>
          </div>
        </div>
        
      </div>
    </nav>
  );
};

export default Nav;
