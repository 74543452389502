import React, { useState } from "react";
import { signInWithEmailAndPassword, getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { app } from '../firebase';
import Logo from '../Admin/Logo'; // Ensure Logo is correctly exported in its fil

const LoginForm = () => {
  const auth = getAuth(app);
  const database = getDatabase(app);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailVerificationError, setEmailVerificationError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setEmailVerificationError('');
    setMessage('');

    try {
      const { user } = await signInWithEmailAndPassword(auth, formData.email, formData.password);
      if (user && !user.emailVerified) {
        setEmailVerificationError('Email not verified. Please verify your email.');
        alert('Email not verified. Please verify your email.');
        setLoading(false);
        return;
      } else {
        alert('Login successful!');
        const authUnsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            const userId = user.uid;
            const userRef = ref(database, `users/${userId}`);
            
            onValue(userRef, (snapshot) => {
              const userData = snapshot.val();
              const userType = userData.type;
              const payment = userData.payment;

              switch (userType) {
                case 'admin':
                  navigate('/AdminDashboard');
                  break;
                case 'user':
                  if (payment) {
                    navigate('/dashboard');
                  } else {
                    navigate('/payment');
                  }
                  break;
                default:
                  navigate('/dashboard');
              }
            });
          }
        });
        return () => authUnsubscribe();
      }
    } catch (error) {
      console.error('Error logging in:', error.message);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-800 to-gray-900">
      <div className="p-8 bg-gray-800 shadow-xl rounded-lg max-w-md w-full">
        <div className="text-center mb-6 w-full ">
          {/* Render Logo Component */}
          <Logo />
          <h1 className="text-2xl font-bold text-white mt-4">Login</h1>
        
        {error && <p className="text-red-500">{error}</p>}
        {emailVerificationError && <p className="text-red-500">{emailVerificationError}</p>}
        {message && <p className="text-green-500">{message}</p>}
        <form onSubmit={handleSubmit} className="space-y-4 w-full mt-5">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="Enter Email"
            className="w-full px-4 py-2 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            placeholder="Enter Password"
            className="w-full px-4 py-2 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <p
            className="text-blue-400 text-sm cursor-pointer hover:underline"
            onClick={() => navigate('/forget')}
          >
            Forget Your Password?
          </p>
          <button
            type="submit"
            disabled={loading}
            className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
          >
            {loading ? 'Loading...' : 'Submit'}
          </button>
        </form>
      </div>
      </div>
    </div>
  );
};

export default LoginForm;


