import { useEffect, useState } from 'react';
import { auth, database } from '../firebase'; // Import Firebase services
import { ref, get, child } from 'firebase/database';

const useUserData = (path) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userId = currentUser.uid;
        const userPath = path || `users/${userId}`;

        try {
          const dbRef = ref(database);
          const snapshot = await get(child(dbRef, userPath));

          if (snapshot.exists()) {
            setData(snapshot.val());
          } else {
            setData(null);
          }
        } catch (err) {
          setError(err);
        }
      } else {
        setError('No authenticated user found');
      }

      setLoading(false);
    };

    fetchData();
  }, [path]);

  return { data, loading, error };
};

export default useUserData;
