import React, { useState } from "react";
import Nav from "../components/Nav";
import useUserData from "../hooks/useUserData";
import DashboardCards from "../components/DashboardCards";
import Todaywork from "../components/TodayWork";
import Wallet from "../components/Wallet";
import Withdraw from "../components/Withdraw";
import Team from "../components/Team";
import Invite from "../components/Invite";
import DailyReward from "../components/Bonus";
import Contact from "../components/Contact";
import Profile from "../components/Profile";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const NewDashboard = () => {
  const navigate = useNavigate(); // React Router hook for navigation

  const { data: userData, loading, error } = useUserData(); // Use the hook
  const [sidebarOpen, setSidebarOpen] = useState(false); // State for sidebar visibility
  const [activeComponent, setActiveComponent] = useState("dashboard");

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen); // Toggle sidebar visibility
  };

  const handleSelectComponent = (component) => {
    setActiveComponent(component); // Set the active component when Dashboard is selected
  };
  const handleLogout = async () => {
    try {
      await signOut(auth); // Firebase sign-out
      console.log("User logged out");
      navigate("/"); // Redirect to home page
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!userData) return <p>No user data available</p>;
  return (
    <div class="relative bg-[#070b18] h-full min-h-screen font-[sans-serif]">
      <div class="flex items-start">
        <Nav
          isOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
          onSelectComponent={handleSelectComponent}
          activeComponent={activeComponent}
        />

        <button
          id="open-sidebar"
          onClick={toggleSidebar}
          class="ml-auto fixed top-[30px] left-[18px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 fill-gray-300"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M.13 17.05a1.41 1.41 0 0 1 1.41-1.41H10a1.41 1.41 0 1 1 0 2.82H1.54a1.41 1.41 0 0 1-1.41-1.41zm0-14.1a1.41 1.41 0 0 1 1.41-1.41h16.92a1.41 1.41 0 1 1 0 2.82H1.54A1.41 1.41 0 0 1 .13 2.95zm0 7.05a1.41 1.41 0 0 1 1.41-1.41h16.92a1.41 1.41 0 1 1 0 2.82H1.54A1.41 1.41 0 0 1 .13 10z"
              clip-rule="evenodd"
              data-original="#000000"
            />
          </svg>
        </button>

        <section class="main-content w-full md:p-6  max-lg:ml-8">
          <div className="p-5 md:p-0">
            <div class="flex  flex-wrap gap-6 items-center justify-center">
              <div className="flex justify-center">
                <h3 class="hidden text-lg font-semibold text-white">
                  <span className="hidden">Welcome back,</span>{" "}
                  {userData?.username}
                </h3>
              </div>

              <div class="ml-auto">
                <div class="flex gap-4">
                  <button
                    onClick={handleLogout}
                    type="button"
                    class="flex items-center px-4 py-2.5 text-sm text-white bg-[#017bfe] border-0 outline-0 rounded"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* components */}
          {activeComponent === "dashboard" && (
            <DashboardCards onSelectComponent={handleSelectComponent} />
          )}
          {activeComponent === "todaywork" && <Todaywork />}

          {activeComponent === "wallet" && (
            <Wallet onSelectComponent={handleSelectComponent} />
          )}
          {activeComponent === "withdraw" && <Withdraw />}
          {activeComponent === "team" && <Team />}
          {activeComponent === "invite" && <Invite />}
          {activeComponent === "reward" && <DailyReward />}
          {activeComponent === "contact" && <Contact />}
          {activeComponent === "profile" && <Profile />}
        </section>
      </div>
    </div>
  );
};

export default NewDashboard;
