import React from 'react';
import { auth } from '../firebase'; // Firebase Auth
import useUserData from '../hooks/useUserData'; // Import the reusable hook
import { IoMdCamera } from 'react-icons/io';

const Profile = () => {
  const { data: userData, loading, error } = useUserData(); // Use the hook

  const getRank = (invites) => {
    if (invites <= 0) return 'Rank 0';
    if (invites >= 1 && invites <= 10) return 'Rank 1';
    if (invites >= 11 && invites <= 25) return 'Rank 2';
    if (invites >= 26 && invites <= 50) return 'Rank 3';
    if (invites >= 51 && invites <= 90) return 'Rank 4';
    if (invites >= 91 && invites <= 140) return 'Rank 5';
    if (invites >= 141 && invites <= 180) return 'Rank 6';
    if (invites >= 181 && invites <= 220) return 'Rank 7';
    if (invites >= 221 && invites <= 260) return 'Rank 8';
    if (invites >= 261 && invites <= 300) return 'Rank 9';
    return 'Unranked';
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!userData) return <p>No user data available</p>;

  const rank = getRank(userData.invites || 0);

  return (
    <div className="min-h-screen mt-4 text-white  md:p-5">
      {/* Dashboard Container */}
      <div className="container mx-auto max-w-full p-1 md:p-8 bg-gray-800 rounded-lg shadow-xl">
        
        {/* Profile Header Section */}
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <img
                src="/images.jpg"
                alt="Profile"
                className=" w-12 h-12  md:w-24 md:h-24 object-cover rounded-full border-4 border-white"
              />
              <IoMdCamera className="absolute bottom-2 right-2 text-white text-2xl cursor-pointer transform hover:scale-110 transition-transform" />
            </div>
            <div>
              <h2 className="text-lg md:text-3xl font-semibold">{userData.name || 'N/A'}</h2>
              <p className="text-sm text-gray-400">{auth.currentUser?.email || 'N/A'}</p>
            </div>
          </div>
        </div>

        {/* Profile Stats Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-8">
          <div className="bg-gradient-to-r from-blue-600 to-blue-500  p-4 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold">Rank</h3>
            <p className="text-2xl mt-2">{rank}</p>
          </div>
          <div className="bg-gradient-to-r from-green-600 to-green-500  p-4 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold">Username</h3>
            <p className="text-2xl mt-2">{userData.username || 'N/A'}</p>
          </div>
          <div className="bg-gradient-to-r from-purple-600 to-purple-500  p-4 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold">Phone Number</h3>
            <p className="text-2xl mt-2">{userData.phone || 'N/A'}</p>
          </div>
        </div>

        {/* Profile Information Section */}
        <div className="mt-8 space-y-4">
          <div className="flex items-center gap-2 md:justify-between py-4 border-b border-gray-600">
            <span className="text-lg text-gray-400">Email:</span>
            <span className="text-sm  md:text-lg">{auth.currentUser?.email || 'N/A'}</span>
          </div>
          <div className="flex items-center gap-2 md:justify-between py-4 border-b border-gray-600">
            <span className="text-lg text-gray-400">Total Invites:</span>
            <span className="text-lg">{userData.invites || 0}</span>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Profile;
