import React from 'react';
import { FaPlusSquare, FaUserCircle } from "react-icons/fa";
import { IoWallet } from "react-icons/io5";
import { MdGroups3 } from "react-icons/md";
import { FcInvite } from "react-icons/fc";
import { AiFillFire } from "react-icons/ai";

const cardData = [
  {
    id: 'profile',
    icon: <FaUserCircle className="w-7 h-7 md:w-10 md:h-10" />,
    title: 'Profile',
    description: 'View Your Profile and see your Rank',
    buttonText: 'View',
  },
  {
    id: 'todaywork',
    icon: <FaPlusSquare className="w-7 h-7 md:w-10 md:h-10" />,
    title: 'Work',
    description: 'View Your today work and complete your task',
    buttonText: 'View',
  },
  {
    id: 'wallet',
    icon: <IoWallet className="w-7 h-7 md:w-10 md:h-10" />,
    title: 'Wallet',
    description: 'View Your current Balance',
    buttonText: 'View',
  },
  {
    id: 'team',
    icon: <MdGroups3 className="w-7 h-7 md:w-10 md:h-10" />,
    title: 'My Team',
    description: 'View Your Team and Progress',
    buttonText: 'View',
  },
  {
    id: 'invite',
    icon: <FcInvite className="w-7 h-7 md:w-10 md:h-10" />,
    title: 'Invite ',
    description: 'Share your referral link with your friends and get money',
    buttonText: 'View',
  },
  {
    id: 'reward',
    icon: <AiFillFire className="w-7 h-7 md:w-10 md:h-10" />,
    title: 'Rewards',
    description: 'Earn money by claiming your rewards',
    buttonText: 'View',
  },
];

const DashboardCards = ({ onSelectComponent }) => {
  return (
    <div className="mt-2 mr-4 mb-6 px-2">
      <div className="grid grid-cols-2 xl:grid-cols-3 md:grid-cols-2 gap-6">
        {cardData.map((card) => (
          <div key={card.id} className="bg-[#0b1739] flex flex-col justify-center items-center md:flex-grow shadow-[0_4px_12px_-5px_rgba(0,0,0,0.4)] p-6 w-full rounded-lg overflow-hidden">
            <div className="flex justify-center">
              <div className="flex w-min bg-[#edf2f7] rounded-lg py-1 px-2">
                {card.icon}
              </div>
            </div>

            <div className="mt-4">
              <h3 className="text-sm md:text-xl font-bold text-white">{card.title}</h3>
              <p className="mt-3 hidden md:flex  text-sm text-gray-300">{card.description}</p>
            </div>

            <div className="mt-6">
              <button onClick={() => onSelectComponent(card.id)} className="px-3 py-2 bg-blue-600 rounded-md text-white">
                {card.buttonText}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardCards;
