import React, { useState } from 'react';

const Contact = () => {


  

  return (
    <div className="bg-gray-900 text-white p-8 rounded-lg shadow-lg w-full max-w-md mx-auto mt-10">
      <div className="text-center mb-3">
        {/* WhatsApp Icon */}
        <a href="https://chat.whatsapp.com/H4QGO70jpc6CxIP93vnG72" target="_blank" rel="noopener noreferrer">
          <img
            src="/whatsapp.png"
            alt="Join WhatsApp Group"
            className="w-12 h-12 mx-auto mb-4"
          />
          <b className="text-2xl font-semibold mb-4">Join on WhatsApp Group</b>
        </a>
      </div>

      <div className="mb-6">
        <p className="text-lg mb-4 text-center">Click below to join the WhatsApp group </p>
      </div>

      <button 
        
        className="w-full py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors duration-200"
      >
        <a href="https://chat.whatsapp.com/H4QGO70jpc6CxIP93vnG72">Join Now</a>
      </button>


    </div>
  );
};

export default Contact;
