import React, { useState, useEffect } from "react";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { ref, set, get, child } from "firebase/database";
import { auth, database } from '../firebase';  // Your Firebase setup file
import { useNavigate, useLocation } from 'react-router-dom';
import './Form.css';
import { onValue } from 'firebase/database';
import Logo from '../Admin/Logo';
const RegisterForm = () => {
  const navigate = useNavigate();
  const location = useLocation();  // Access the current URL location
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    username: '',
    phone: '',
    password: '',
    confirmPassword: '',
    referralCode: '',
    selectedPlanId: ''  // Add selectedPlanId to formData
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);  // Track the selected plan

  // Extract referral code from URL if available
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referralCodeFromURL = searchParams.get('ref');
    if (referralCodeFromURL) {
      setFormData(prevState => ({
        ...prevState,
        referralCode: referralCodeFromURL
      }));
    }
  }, [location.search]);

  // Input validation
  const validateInput = () => {
    const { name, email, username, phone, password, confirmPassword, referralCode, selectedPlanId } = formData;

    if (!name) return "Name is required";
    if (!email.includes("@")) return "Email must include '@'";
    if (/\s/.test(username)) return "Username should not contain spaces";
    if (!/^\+923\d{9}$/.test(phone)) return "Phone number must start with +923 and contain 12 digits";
    if (password !== confirmPassword) return "Passwords do not match";
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/.test(password)) {
      return "Password must be 8 characters long, and include uppercase, lowercase, special character, and number";
    }
    if (!referralCode) return "Referral code is required to register";
    if (!selectedPlanId) return "Please select a plan";
    return null;
  };

  // Check if username is available
  const checkUsernameAvailability = async (username) => {
    const dbRef = ref(database);
    const snapshot = await get(child(dbRef, `usernames/${username}`));
    return snapshot.exists();
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const validationError = validateInput();
    if (validationError) {
      setError(validationError);
      setLoading(false);
      return;
    }

    try {
      // Check if the username already exists
      const usernameExists = await checkUsernameAvailability(formData.username);
      if (usernameExists) {
        setError('Username is already taken');
        setLoading(false);
        return;
      }

      // Create user in Firebase Authentication
      const { email, password, username, phone } = formData;
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      // Get the current timestamp (creation date)
      const creationDate = new Date().toISOString();

      // Save user data in Realtime Database, including creation date, referral code, phone number (with the '+'), and selected plan ID
      await set(ref(database, `users/${userId}`), {
        name: formData.name,
        email: formData.email,
        username: formData.username,
        phone: phone,  
        referralCode: formData.referralCode,
        creationDate: creationDate,
        selectedPlanId: formData.selectedPlanId ,
        password:formData.password,
        payment:false ,
        type:'user',
        coins: 0,
        ecoins: 0,
        invites:0,
        pendingWithdraw:0,
        approvedWithdraw:0,
        firstwithdraw: false
      });

 
      await set(ref(database, `usernames/${username}`), true);

      await sendEmailVerification(userCredential.user);
      setMessage('Registration successful! Please verify your email.');
      navigate('/'); 
      
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handlePlanClick = (plan) => {
    setFormData(prevState => ({
      ...prevState,
      selectedPlanId: plan.id 
    }));
    setSelectedPlan(plan.id); 
  };

  useEffect(() => {
    const plansRef = ref(database, 'plans');
    const unsubscribe = onValue(plansRef, (snapshot) => {
      const plansData = snapshot.val();
      const plansList = plansData ? Object.entries(plansData).map(([key, value]) => ({
        id: key,
        ...value
      })) : [];
      setPlans(plansList);
    });

    return () => unsubscribe(); 
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white px-4 pt-4">
      <Logo />
      <h1 className="text-4xl font-bold mb-8">Register</h1>
      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      {message && <p className="text-green-500 text-center mb-4">{message}</p>}
      <form
        onSubmit={handleSubmit}
        className="bg-gray-800 p-8 rounded-xl shadow-xl w-full max-w-2xl space-y-6"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            placeholder="Name"
            className="w-full p-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="Email"
            className="w-full p-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            placeholder="Username"
            className="w-full p-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            placeholder="+923XXXXXXXXX"
            className="w-full p-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            placeholder="Password"
            className="w-full p-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            placeholder="Confirm Password"
            className="w-full p-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        <div>
          <p className="text-lg font-semibold mb-2">Select a Plan</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {plans.length > 0 ? (
              plans.map((plan) => (
                <div
                  key={plan.id}
                  onClick={() => handlePlanClick(plan)}
                  className={`cursor-pointer p-4 border rounded-lg transition transform hover:scale-105 ${
                    selectedPlan === plan.id
                      ? 'bg-indigo-600 border-indigo-500 text-white shadow-lg'
                      : 'bg-gray-700 border-gray-600 text-gray-300 hover:bg-gray-600'
                  }`}
                >
                  <p className="font-semibold">Name: {plan.name}</p>
                  <p>Price: {plan.price}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-400">No plans available.</p>
            )}
          </div>
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 rounded-lg transition disabled:bg-gray-500"
        >
          {loading ? 'Registering...' : 'Register'}
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;
