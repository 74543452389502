import React from 'react';
import { auth } from '../firebase'; // Firebase Auth
import useUserData from '../hooks/useUserData'; // Import the reusable hook
import './Profile.css'; // Import CSS
import { IoMdCamera } from 'react-icons/io';

const Profile = () => {
  const { data: userData, loading, error } = useUserData(); // Use the hook

  const getRank = (invites) => {
    if (invites <= 0) return 'Rank 0';
    if (invites >= 1 && invites <= 10) return 'Rank 1';
    if (invites >= 11 && invites <= 25) return 'Rank 2';
    if (invites >= 26 && invites <= 50) return 'Rank 3';
    if (invites >= 51 && invites <= 90) return 'Rank 4';
    if (invites >= 91 && invites <= 140) return 'Rank 5';
    if (invites >= 141 && invites <= 180) return 'Rank 6';
    if (invites >= 181 && invites <= 220) return 'Rank 7';
    if (invites >= 221 && invites <= 260) return 'Rank 8';
    if (invites >= 261 && invites <= 300) return 'Rank 9';
    return 'Unranked';
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!userData) return <p>No user data available</p>;

  const rank = getRank(userData.invites || 0);

  return (
    <div className="profile-card">
      <div className="profile-image">
        <img src="/images.jpg" alt="Profile" />
      </div>
      <IoMdCamera className="camera-icon" />
      <div className="profile-info">
        <div className="profile-item">
          <span className="profile-label">Name:</span>
          <span className="profile-value">{userData.name || 'N/A'}</span>
        </div>
        <div className="profile-item">
          <span className="profile-label">Email:</span>
          <span className="profile-value">{auth.currentUser?.email || 'N/A'}</span>
        </div>
        <div className="profile-item">
          <span className="profile-label">Rank:</span>
          <span className="profile-value">{rank}</span>
        </div>
        <div className="profile-item">
          <span className="profile-label">Username:</span>
          <span className="profile-value">{userData.username || 'N/A'}</span>
        </div>
        <div className="profile-item">
          <span className="profile-label">Phone Number:</span>
          <span className="profile-value">{userData.phone || 'N/A'}</span>
        </div>
      </div>
    </div>
  );
};

export default Profile;
